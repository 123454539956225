import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import { remCalc } from '../util/math'
import Intro from '../components/intro'
import MainContent from '../components/mainContent'
import SEO from '../components/seo'

const Project = ({ data }) => {
  const project = data.prismicProject.data

  let mainMediaType = null

  if (project.main_video.text) {
    mainMediaType = 'video'
  } else {
    mainMediaType = 'img'
  }
  return (
    <Layout>
      <SEO title={`Project: ${project.title.text}`} description={project.intro.text} />
      <Intro>
        <h1>{project.title.text}</h1>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: project.intro.html }}
        />
      </Intro>
      {mainMediaType === 'video' && (
        <div
          className="main-video"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: project.main_video.text }}
        />
      )}
      {mainMediaType === 'img'
        && <div className="main-image"><img src={project.main_image.url} alt={project.main_image.alt} /></div>}

      <MainContent html={project.content.html} />

      <style jsx>
        {`
        .main-image, .main-video {
          margin: 4rem auto;
          max-width: ${remCalc(800)};
        }
      `}
      </style>
    </Layout>
  )
}

Project.propTypes = {
  data: PropTypes.shape({
    prismicProject: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string
        }),
        intro: PropTypes.shape({
          html: PropTypes.string,
          text: PropTypes.string
        }),
        content: PropTypes.shape({
          html: PropTypes.string
        }),
        main_video: PropTypes.shape({
          text: PropTypes.string
        }),
        main_image: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string
        })
      })
    })
  }).isRequired
}

export default Project

export const pageQuery = graphql`
  query($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        intro {
          html
          text
        }
        content {
          html
        }
        main_video {
          text
        }
        main_image {
          url
          alt
        }
      }
    }
  }
`
